<template>
    <div id="appNavbar">
      <ServerError v-if="ServerError" />
      <v-navigation-drawer v-model="sideNav" fixed temporary color="#202020">
        <v-layout wrap justify-center>
          <v-flex xs12 v-for="(item, i) in sellerNavItems" :key="i" text-center pa-2 pl-4 text-uppercase align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <router-link :to="item.route">
                  <span :style="
                    $route.path == item.route
                      ? {
                        'font-family': 'poppinsbold',
                      }
                      : {
                        'font-family': 'poppinsthin',
                      }
                  " style="
                        font-size: 15px;
                        color: #ffffff;
                        letter-spacing: 3px;
                        cursor: pointer;
                      ">
                    {{ item.name }}
                  </span>
                </router-link>
              </v-flex>
              <v-flex xs12 pt-2>
                <v-divider color="#fff"></v-divider>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 text-center pa-2 pl-4 text-uppercase align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <span @click="appLogout" style="
                      font-family: poppinsthin;
                      font-size: 15px;
                      color: #ffffff;
                      letter-spacing: 3px;
                      cursor: pointer;
                    ">
                  Logout
                </span>
              </v-flex>
              <v-flex xs12 pt-2>
                <v-divider color="#fff"></v-divider>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-navigation-drawer>
  
      <v-app-bar app dark color="#ffffff" dense flat height="50px" class="hidden-lg-and-up">
        <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
          <v-icon color="#ff0000">mdi-menu</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title>
          <router-link to="/">
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-img src="./../../assets/greenlogo.png" height="25px" contain alt="STYLOOP"></v-img>
              </v-flex>
            </v-layout>
          </router-link>
        </v-toolbar-title>
        <v-app-bar-nav-icon class="hidden-md-and-up pr-4">
          <router-link to="/Cart">
            <v-badge color="#FF0000" :content="appCart ? appCart : '0'" right small offset-y="15px">
              <v-icon color="#878787" size="25">mdi-cart-outline</v-icon>
            </v-badge>
          </router-link>
        </v-app-bar-nav-icon>
      </v-app-bar>
      <v-app-bar color="#ffffff" elevation="0" height="70px" class="hidden-md-and-down">
        <v-layout wrap justify-center>
          <v-flex xs2 align-self-center pr-lg-6 pr-xl-12>
            <router-link to="/">
              <v-img src="./../../assets/greenlogo.png" height="60px" contain alt="STYLOOP"></v-img>
            </router-link>
          </v-flex>
          <v-flex xs4 text-left align-self-center>
            <v-layout wrap justify-center fill-height>
              <v-flex xs12 text-left align-self-end>
                <span v-if="appUser.sellerId" style="font-family: poppinssemibold; font-size: 20px">
                  <!-- {{ appUser.firstname }} -->
                  <!-- {{ appUser.lastname }} -->
                  {{ appUser.sellerId.shopName }}
                </span>
              </v-flex>
              <v-flex xs12 text-left align-self-start>
                <span v-if="appUser.role== 'StoreUser'" style="font-family: poppinslight; font-size: 15px">
                 Store User
                </span>
                <span v-else style="font-family: poppinslight; font-size: 15px">
                  {{ appUser.role }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
  
          <v-spacer></v-spacer>
          <!-- <v-flex xs2 lg2 xl1 align-self-center>
            <v-form @submit.prevent="appSearch">
              <v-text-field class="searchBox" v-model="searchKey" prepend-inner-icon="mdi-magnify"
                placeholder="Search for products" solo flat hide-details color="#FF0F0F"></v-text-field>
            </v-form>
          </v-flex> -->
          <v-flex xs6 lg2>
            <v-layout wrap justify-center>
              <v-flex xs3 pt-2 v-if="appUser.sellerId">
                <v-avatar>
                  <v-img :src="mediaUURL + appUser.sellerId.profilePicture" :alt="appUser.name">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="#FF2323"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
              </v-flex>
              <v-flex xs6>
                <v-layout wrap justify-center fill-height>
                  <v-flex xs12 text-left align-self-end>
                    <span style="font-family: opensansbold; font-size: 15px">
                      {{ appUser.name }}
                    </span>
                  </v-flex>
                  <v-flex xs12 text-left align-self-start>
                    <span @click="appLogout" style="
                          cursor: pointer;
                          font-family: opensanssemibold;
                          font-size: 13px;
                          color: #979696;
                        ">
                      Log Out
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-app-bar>
    </div>
  </template>
  <script>
//   import AccountMenu from "./accountMenu";
  // import axios from "axios";
  export default {
    components: {
    //   AccountMenu,
    },
    data() {
      return {
        appLoading: false,
        ServerError: false,
        sideNav: false,
        sideNav2: false,
        user: {},
        navItems: [
          { name: "Wishlist", route: "/Wishlist", type: "link" },
          { name: "Cart", route: "/Cart", type: "link" },
          { name: "Profile", route: "/Profile", type: "link" },
        ],
        sellerNavItems: [
          { name: "ANALYTICS", route: "/" },
          { name: "ADD A PRODUCT", route: "/AddProduct" },
          { name: "PRODUCT SOLD", route: "/SoldProducts" },
          { name: "PENDING ORDERS", route: "/PendingOrders" },
          { name: "OFFERS", route: "/Offers" },
          { name: "INVENTORY", route: "/Inventory" },
          { name: "PROFILE", route: "/Profile" },
        ],
        userName: null,
        profilePic: null,
        cartItems: 0,
        searchKey: null,
      };
    },
    beforeMount() {
      this.searchKey = this.$route.query.searchKey;
      // this.$store.dispatch("getCategory");
      // this.$store.dispatch("getStylCategory");
      // this.$store.dispatch("getSubCategory");
      // this.getCart();
    },
    computed: {
      appUser() {
        return this.$store.state.userData;
      },
      appCart() {
        if (this.$store.state.cartItems == undefined) {
          return 0;
        } else {
          return this.$store.state.cartItems;
        }
      },
      appLogin() {
        return this.$store.state.isLoggedIn;
      },
      appType() {
        return this.$store.state.userType;
      },
    },
  
    methods: {
      appLogout() {
        this.$store.commit("logoutUser", true);
        if (this.sideNav2 == true) {
          this.sideNav2 = false;
        }
      },
      changeRoute(item) {
        localStorage.setItem("isBrand", item.name);
        if (item.name != "Offer" && item.name != "Brands") {
          this.$router.push({
            path: "/Products",
            query: {
              styl:  this.$route.query.styl != item.urlname ? this.stylCategories[0].urlname : null,
              category:
                this.$route.query.category != item.urlname ? item.urlname : null,
              subcategory: this.$route.query.subcategory ? null : null,
              searchKey: this.$route.query.searchKey,
              // type: this.$route.query.type,
            },
            
          });
          // console.log("cat", this.$route.query.category)
  
        } else if (item.name == "Brands") {
          this.$router.push({
            path: "/Products",
            query: {
              styl: this.$route.query.styl,
              // subcategory: this.$route.query.subcategory,
              subcategory: this.$route.query.subcategory
                ? this.subCategories[0].urlname
                : null,
              searchKey: this.$route.query.searchKey,
              type: this.$route.query.type != item.urlname ? item.urlname : null,
              brandonly: true,
              brandName:this.$route.query.brandName ? this.$route.query.brandName
                : null
            },
          });
        } else {
          this.$router.push({
            path: "/Products",
            query: {
              styl: this.$route.query.styl,
              // subcategory: this.$route.query.subcategory,
              subcategory: this.$route.query.subcategory
                ? this.subCategories[0].urlname
                : null,
              searchKey: this.$route.query.searchKey,
              type: this.$route.query.type != item.urlname ? item.urlname : null,
            },
          });
        }
      },
  
      changeStylRoute(item) {
        this.$router.push({
          path: "/Products",
          query: {
            styl: this.$route.query.styl != item.urlname ? item.urlname : null,
            category: this.$route.query.category,
            subcategory: this.$route.query.subcategory,
            searchKey: this.$route.query.searchKey,
            type: this.$route.query.type,
          },
        });
      },
      appSearch() {
        if (this.appType == "Client" || this.appType == "Affliate") {
          if (this.$route.query.searchKey != this.searchKey) {
            this.$router.push({
              path: "/Products",
              query: {
                styl: this.$route.query.styl,
                category: this.$route.query.category,
                subcategory: this.$route.query.subcategory,
                searchKey: this.searchKey,
                type: this.$route.query.type,
              },
            });
          } else {
            return;
          }
        } else {
          if (this.$route.query.searchKey != this.searchKey) {
            this.$router.push({
              path: "/Inventory",
              query: {
                searchKey: this.searchKey,
              },
            });
          } else {
            return;
          }
        }
      },
    },
  };
  </script>
  
  <style>
  input::-webkit-input-placeholder {
    color: #c5c5c5 !important;
    font-size: 14px !important;
    font-family: poppinsregular !important;
  }
  
  input::-moz-placeholder {
    color: #c5c5c5 !important;
    font-size: 14px !important;
    font-family: poppinsregular !important;
  }
  
  .searchBox .v-input__control {
    min-height: 10px !important;
  }
  
  .searchBox .v-input__control .v-input__slot {
    padding: 0px !important;
  }
  </style>
  