import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import axios from 'axios'
import store from './store'
import VueElementLoading from 'vue-element-loading'
import ServerError from './components/Common/500'
import ImageLoader from './components/Common/imageLoader'
import PageLoader from './components/Common/pageLoader'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


Vue.component('ServerError', ServerError)
Vue.component('ImageLoader', ImageLoader)
Vue.component('PageLoader', PageLoader)
Vue.component('VueElementLoading', VueElementLoading)

Vue.config.productionTip = false

window.axios = require('axios')

// Vue.prototype.mediaUURL = "https://mudumalaitigereserve.s3.ap-south-1.amazonaws.com/"
// axios.defaults.baseURL = "http://192.168.55.194:4400";
// // Vue.prototype.mediaURL = "http://192.168.55.194:4400/wp/";
// Vue.prototype.ipURL = "http://192.168.55.194:4400"

Vue.prototype.mediaUURL = "https://mudumalaitigereserve.s3.ap-south-1.amazonaws.com/"
axios.defaults.baseURL = "https://api.mudumalaiecoshop.com";
Vue.prototype.mediaURL = "https://api.mudumalaiecoshop.com/wp/";
Vue.prototype.ipURL = "https://api.mudumalaiecoshop.com"


// Vue.prototype.axios = axios
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')