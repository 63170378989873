<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-app id="app">
      <v-snackbar
        v-model="showSnackBar"
        color="#30B868"
        left
        :timeout="timeout"
      >
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-divider></v-divider>
      <!-- <AppHeader v-if="$route.path == '/'" @stepper="winStepper" /> -->
      <AppHeader v-if="$route.name != 'Login'"
      />
      <v-divider v-if="$route.name != 'Login'"></v-divider>
      <router-view
        style="min-height: 80vh"
        :key="$route.fullPath"
        v-bind:storage="sideNav"
      />
      <AppFooter
      />
    </v-app>
  </div>
</template>
<script>
import AppHeader from "./components/Common/appHeader";
import AppFooter from "./components/Common/appFooter";
import axios from "axios";

import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      sideNav: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
    };
  },
  beforeMount() {
   
    // if (typeof localStorage.getItem("token") == "string") {
      this.getProfile();
    // }
  },
  mounted() {
  },
  methods: {
    getProfile() {
      axios({
        method: "GET",
        url: "/storeUser/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("loginUser", response.data.status);
            this.$store.commit("userData", response.data.user);
            this.$store.commit("userType", response.data.user.role);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // winStepper(windowData) {
    //   this.sideNav = windowData.sideNav;
    // },
  },
};
</script>
