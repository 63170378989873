import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem("token"),
        userType: localStorage.getItem('userType'),
        // userType: 'Client',
        cartItems: 0,
        wishlistItems: 0,
        userData: {},
        coupen: {},
        buyNGetMDeal: 0,
        loginAlternate: false,
        fullPath: null,
        categories: [],
        stylCategories: [],
        subCategories: [],
        subCategoriesAll: [],
        storyCategories: [],
        cities: [],
        guestId: null,
        link: "",
        code: "",
        // hasGuestId:false,
    },
    mutations: {
        loginUser(state, item) {
            state.isLoggedIn = item

        },
        guestIdUpdation(state, item) {
            state.guestId = item
                // state.hasGuestId=true

        },

        LOGIN_ALTERNATE(state, item) {
            state.loginAlternate = item

        },

        lastPage_fullPath(state, item) {
            // console.log(state,item,"fghj")
            state.fullPath = item
        },

        logoutUser(state) {
            axios({
                method: "GET",
                url: "/logout",
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then(response => {
                if (response.data) {
                    state.userType = 'Client'
                    state.userData = {}
                    state.coupen = {}
                    localStorage.setItem("userType", 'Client');
                    state.isLoggedIn = false
                    state.cartItems = 0
                    state.wishlistItems = 0
                    localStorage.removeItem("token");
                    router.push({ path: "/loginForm" });
                }
            })
        },

        changeCart(state, item) {
            state.cartItems = item
        },
        changeWishlist(state, item) {
            state.wishlistItems = item
        },
        userData(state, item) {
            state.userData = item
        },
        coupen(state, item) {
            state.coupen = item
        },
        link(state, item) {
            state.link = item
        },
        code(state, item) {
            state.code = item
        },
        changePicture(state, item) {
            state.userData.image = item
        },
        changeCoverPicture(state, item) {
            state.userData.seller.coverimage = item
        },
        userType(state, item) {
            state.userType = item
            localStorage.setItem("userType", item);
        },
        changeBuyNGetMDeal(state, item) {
            state.buyNGetMDeal = item
        },
        sessionOut(state) {
            localStorage.removeItem("token");
            state.isLoggedIn = false
            router.push('/')
        },
    },
    actions: {

    }

})