import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import axios from 'axios'
Vue.use(Router)
let router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            meta: {
                requiresAuth: true,
                // isClient: false,
                // isSeller: true,
                // isBrand: true,
                // isAffiliate: false,

            },
            component: () =>
                import ('./components/Common/appSellerDashboard'),
            children: [{
                    path: '',
                    name: 'Seller',
                    component: () =>
                        import ('./components/Seller/Analytics/analytics'),
                },
                {
                    path: '/Inventory',
                    name: 'Inventory',
                    component: () =>
                        import ('./components/Seller/Inventory/inventory'),
                },
                {
                    path: '/AddProduct',
                    name: 'AddProduct',
                    component: () =>
                        import ('./components/Seller/Product/addProduct'),
                },
                {
                    path: '/Product/:id',
                    name: 'SellerProductDetails',
                    props: true,
                    component: () =>
                        import ('./components/Seller/ProductDetails/product'),
                },
                {
                    path: '/Product/Edit/:id',
                    name: 'EditProduct',
                    props: true,
                    component: () =>
                        import ('./components/Seller/Product/editProduct'),
                },
                {
                    path: '/Product/editPromocode/:id',
                    name: 'EditPromocode',
                    props: true,
                    component: () =>
                        import ('./components/Seller/Product/editPromocode'),
                },
                {
                    path: '/Product/:status/:id',
                    name: 'ProductStatus',
                    props: true,
                    component: () =>
                        import ('./components/Seller/Product/productStatus'),
                },
                {
                    path: '/SoldProducts',
                    name: 'SoldProducts',
                    component: () =>
                        import ('./components/Seller/SoldProducts/soldProducts'),
                },
                {
                    path: '/PendingOrders',
                    name: 'PendingOrders',
                    component: () =>
                        import ('./components/Seller/PendingOrders/pendingOrders'),
                },
                {
                    path: '/ShippingOrders',
                    name: 'ShippingOrders',
                    component: () =>
                        import ('./components/Seller/ShippingOrders/shippingOrders'),
                },
                {
                    path: '/AcceptedOrders',
                    name: 'AcceptedOrders',
                    component: () =>
                        import ('./components/Seller/AcceptedOrders/acceptedOrders'),
                },
                {
                    path: '/orderDetails',
                    name: 'orderDetails',
                    component: () =>
                        import ('./components/Seller/PendingOrders/orderDetails'),
                },
                {
                    path: '/CancelledOrders',
                    name: 'CancelledOrders',
                    component: () =>
                        import ('./components/Seller/CancelledOrders/cancelledOrders'),
                },
                {
                    path: '/Orders/:id',
                    name: 'OrderDetails',
                    props: true,
                    component: () =>
                        import ('./components/Seller/Order/order'),
                },
                {
                    path: '/Offers',
                    name: 'Offers',
                    component: () =>
                        import ('./components/Seller/Offers/offers'),
                },
                {
                    path: '/Offers/Add/:id',
                    name: 'AddOffer',
                    props: true,
                    component: () =>
                        import ('./components/Seller/Offers/addOffer'),
                },
                {
                    path: '/ProductReviews',
                    name: 'ProductReviews',
                    component: () =>
                        import ('./components/Seller/Reviews/productReviews'),
                },
                {
                    path: '/SellerReviews',
                    name: 'SellerReviews',
                    component: () =>
                        import ('./components/Seller/Reviews/sellerReviews'),
                },
                {
                    path: '/Promocodes',
                    name: 'Promocodes',
                    component: () =>
                        import ('./components/Seller/Promocode/promocodes'),
                },
                {
                    path: '/Deals',
                    name: 'Deals',
                    component: () =>
                        import ('./components/Seller/Deals/deals'),
                },
                {
                    path: '/Deals/Add/:id',
                    name: 'AddDeal',
                    props: true,
                    component: () =>
                        import ('./components/Seller/Deals/addDeal'),
                },
                {
                    path: '/TrialRooms',
                    name: 'TrialRooms',
                    component: () =>
                        import ('./components/Seller/TrialRooms/trialRoom'),
                },
                {
                    path: '/Profile',
                    name: 'SellerProfile',
                    component: () =>
                        import ('./components/Seller/profile'),
                    props: true,

                },
                {
                    path: '/Gallery',
                    name: 'SellerGallery',
                    component: () =>
                        import ('./components/Seller/Gallery/gallery'),
                    props: true,

                },
                {
                    path: '/OrderReports',
                    name: 'OrderReports',
                    component: () =>
                        import ('./components/Seller/Reports/orderReports'),
                },
                {
                    path: '/ItemWiseReports',
                    name: 'ItemWiseReports',
                    component: () =>
                        import ('./components/Seller/Reports/itemWiseReports'),
                },
            ]
        },
        {
            path: "/loginForm",
            name: "Login",
            component: () =>
                import ("./components/Login/loginForm"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: '/ServerError',
            name: 'ServerError',
            props: true,
            component: () =>
                import ('./components/Common/500'),
            meta: {
                requiresAuth: false,
                isClient: true,

            }
        },
        {
            path: '*',
            name: '404PageNotFound',
            props: true,
            component: () =>
                import ('./components/Common/404'),
            meta: {
                requiresAuth: false,
                isClient: true,

            }
        },

    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
})
router.beforeEach((to, from, next) => {
    if (localStorage.getItem('token')) {
        var newURL = ''
        newURL = "/validate/token";
        axios({
                method: "POST",
                url: newURL,
                headers: {
                    "token": localStorage.getItem("token")
                }
            })
            .then(response => {
                if (!response.data.status) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("role");
                    store.commit("logoutUser");
                    store.commit("sessionOut", true)
                    next({
                        name: 'Login'
                    })
                    return
                }
            })
    }

    if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
        next({
            name: 'Login'
        })
        return
    }
    if (to.path === '/loginForm' && store.state.isLoggedIn) {
        next({
            path: '/'
        })
        return
    }
    next()
})

export default router